
import { computed, defineComponent, onMounted, ref, reactive} from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import mAxiosApi from "@/api";
import mAxios from "axios";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

interface Step1 {
  siteNum: any;
  siteLabel: string;
  siteAffMap: boolean;
  siteTypeCustom: string;
  siteType: string;
  contacts: any;
}

interface Step2 {
  adr_ligne_1: string;
  adr_ligne_2: string;
  adr_cp: string;
  adr_ville: string;
  adr_pays: string;
  adr_coord: any;
}

interface Step3 {
  shortenedDescriptor: string;
  corporationType: string;
  businessDescription: string;
  contactEmail: string;
}

interface Step4 {
  adr_complement: string;
  nameOnCard: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvv: string;
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "create-edit-site",
  props: {
    mCode: String,
  },
  components: {
    Field,
    ErrorMessage,
  },
  setup(props) {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const createEditSiteRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const router = useRouter();
    const { t } = useI18n()


    const mCode =
          props.mCode && props.mCode.length > 0
            ? props.mCode
            : (router.currentRoute.value.params.code as string);

    const state = reactive({
      idSite: 0,      
      titleModal : 'Ajouter un nouveau site',
      subTitle : 'Création du site',
      rerender : 0,
      listGeoLoc : null,
      listContacts : [],
      newContact : false,
      con_civilite: 0,
      con_prenom: '',
      con_nom: '',
      con_tel1: '',
      con_tel2: '',
      con_mail: '',
      formData : ref<KTCreateApp>({
            siteNum: 0,
            siteLabel: "",
            siteAffMap : false,
            siteTypeCustom: "",
            siteType: '',
            adr_ligne_1: '',
            adr_ligne_2: '',
            adr_cp: '',
            adr_ville: '',
            adr_pays: '',
            adr_complement: '',
            adr_coord: [],
            shortenedDescriptor: "KEENTHEMES",
            corporationType: "1",
            businessDescription: "",
            contactEmail: "corp@support.com",
            nameOnCard: "Max Doe",
            cardNumber: "4111 1111 1111 1111",
            expirationMonth: "1",
            expirationYear: "2022",
            cvv: "123",
            contacts: [],
          }),
    });

    const mAxiosGen = mAxios.create({
      withCredentials: false,
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );
      if(mCode == "0"){
        addSite();
      } else {
        editSite(mCode)
      }

    });

    const createAppSchema = [
      Yup.object({
        siteLabel: Yup.string().required(
          t("Le libellé du site est obligatoire pour continuer.")
        ),
        siteTypeCustom: Yup.string().when("state.formData.siteType", {
          is: "4",
          then: Yup.string().required(
            t("Le libellé du type est obligatoire pour continuer.")
          ),
        }),
      }),
      Yup.object({}),
      Yup.object({}),
      Yup.object({}),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) return;
      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) return;
      currentStepIndex.value--;
      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            if(item == 'contacts') continue;
            if(item == 'adr_coord') continue;
            state.formData[item] = values[item];
          }
        }
      }

      currentStepIndex.value++;
      if (!_stepperObj.value) return;
      
      _stepperObj.value.goNext();
    });

    const searchGeoLoc = async (obj) => {
      const stringAdr = encodeURI("street=" + obj.adr_ligne_1 + " " + obj.adr_ligne_2 + "&postcode=" + obj.adr_cp +  "&city=" + obj.adr_ville + "&country="+obj.adr_pays);
      
      const response = await mAxiosGen.get("https://api.geoapify.com/v1/geocode/search?" + stringAdr + "&limit=5&apiKey=1b48259b810e48ddb151889f9ea58db0");
      if (response.data.features.length > 0) {
        
        state.listGeoLoc = response.data.features;
        state.formData.adr_coord = response.data.features[0].geometry.coordinates;
        //console.log(state.formData.adr_coord);
      }
    }

    const formSubmit = () => {
      const sendData = state.formData;
        mAxiosApi
          .post("addupdateSite", { sendData })
          .then( async () => {
              Swal.fire({
                text: t("Opération réalisée avec succés !"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("Retour à la gestion des sites"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(() => {
                // hideModal(createEditSiteRef.value);
                router.push({  name: "sites" });
              });
          })
          .catch((err) => {
            console.error(err);
          });

    };

    resetForm({
      values: {
        ...state.formData,
      },
    });

    async function addSite() {
      state.idSite = 0;
      state.titleModal = "Ajout d'un nouveau site";
      state.subTitle = "Création du site";
      resetForm();
      state.formData.siteNum = 0;       

      await listContacts();      
      const arrayContacts=[0];
      state.formData.contacts = arrayContacts;     
      state.formData.siteAffMap = true;

      for (let i=0; i<=currentStepIndex.value; i++) if(currentStepIndex.value>0) previousStep();

    }

    async function listContacts() {
      let myList = await mAxiosApi.prototype.getAxios("/getContacts");
      state.listContacts = myList.results;
    }

    function new_contact() {
      state.con_civilite = 0;
      state.con_prenom = "";
      state.con_nom = "";
      state.con_tel1 = "";
      state.con_tel2 = "";
      state.con_mail = "";
      state.newContact = true;
    }

    async function editSite(idSite) {
        state.newContact = false;
        state.idSite = idSite;
        state.titleModal = "Modification d'un site";
        state.subTitle = "Modifier le site";

        let myList = await mAxiosApi.prototype.getAxios("/getSite/" + idSite);
        for (let i=0; i<=currentStepIndex.value; i++) if(currentStepIndex.value>0) previousStep();
        await listContacts();      
        const arrayContacts=[0];
        state.listContacts.forEach(
          function(item: any){
            if(item.con_knum_adr === idSite) {
              arrayContacts.push(item.con_seq);
            }
          }
        );
        state.formData.contacts = arrayContacts;
        state.formData.siteNum = idSite;       

        state.listGeoLoc = null;
        state.formData.adr_coord = [myList.results[0].adr_lng, myList.results[0].adr_lat];
        state.formData.siteLabel = myList.results[0].adr_c_site_label ? myList.results[0].adr_c_site_label : '';
        state.formData.siteAffMap = myList.results[0].adr_x_aff_carte === "1";
        
        switch (myList.results[0].adr_c_site_type) {
          case 'Livraison':
          case '':
          case null:
            state.formData.siteTypeCustom = "";
            state.formData.siteType = "1";
            break;
          case 'Siège social':
            state.formData.siteTypeCustom = "";
            state.formData.siteType = "2";
            break;
          case 'Stockage':
            state.formData.siteTypeCustom = "";
            state.formData.siteType = "3";
            break;
          default:
            state.formData.siteTypeCustom = myList.results[0].adr_c_site_type ? myList.results[0].adr_c_site_type : '';
            state.formData.siteType = "4";
        }

        state.formData.adr_ligne_1 = myList.results[0].adr_ligne_1;
        state.formData.adr_ligne_2 = myList.results[0].adr_ligne_2;
        state.formData.adr_cp = myList.results[0].adr_cp;
        state.formData.adr_ville = myList.results[0].adr_ville;
        state.formData.adr_pays = myList.results[0].adr_pays;
        state.formData.adr_complement = myList.results[0].adr_complement;

    }

    const saveNewContact = async () => {
        mAxiosApi
          .post("addContact", { con_civilite: state.con_civilite, con_prenom: state.con_prenom, con_nom: state.con_nom, con_tel1: state.con_tel1, con_tel2: state.con_tel2, con_mail: state.con_mail, con_knum_adr: state.idSite>0 ? state.idSite : null })
          .then( async () => {
            await listContacts();
            state.newContact = false;
          })
          .catch((err) => {
            console.error(err);
          });
    };


    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      state,
      createEditSiteRef,
      addSite,
      editSite,
      searchGeoLoc,
      listContacts,
      new_contact,
      saveNewContact
    };
  },
});
